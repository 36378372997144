$(function() {

    // Mobile menu overlay

    (function() {
        var triggerBttn = document.getElementById( 'JT-trigger-overlay' );
        overlay = document.querySelector( 'div.JT-overlay' );
        closeBttn = overlay.querySelector( 'button.JT-overlay-close' );
        transEndEventNames = {
            'WebkitTransition': 'webkitTransitionEnd',
            'MozTransition': 'transitionend',
            'OTransition': 'oTransitionEnd',
            'msTransition': 'MSTransitionEnd',
            'transition': 'transitionend'
        },
        transEndEventName = transEndEventNames[ Modernizr.prefixed( 'transition' ) ],
        support = { transitions : Modernizr.csstransitions };

        function toggleOverlay() {
            if( classie.has( overlay, 'JT-open' ) ) {
                classie.remove( overlay, 'JT-open' );
                classie.add( overlay, 'JT-close' );
                var onEndTransitionFn = function( ev ) {
                    if( support.transitions ) {
                        if( ev.propertyName !== 'visibility' ) return;
                        this.removeEventListener( transEndEventName, onEndTransitionFn );
                    }
                    classie.remove( overlay, 'JT-close' );
                };
                if( support.transitions ) {
                    overlay.addEventListener( transEndEventName, onEndTransitionFn );
                }
                else {
                    onEndTransitionFn();
                }
            }
            else if( !classie.has( overlay, 'JT-close' ) ) {
                classie.add( overlay, 'JT-open' );
            }
        }
        if(!triggerBttn.addEventListener){
            triggerBttn.attachEvent("onclick", toggleOverlay);
        }else{
            triggerBttn.addEventListener("click", toggleOverlay, false);
        }

        if(!closeBttn.addEventListener){
            closeBttn.attachEvent("onclick", toggleOverlay);
        }else{
            closeBttn.addEventListener("click", toggleOverlay, false);
        }
    })();

});
